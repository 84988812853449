<template>
  <div style="cursor: pointer">
    <v-img
      :width="width"
      :height="height"
      :src="src"
      @click="showImage"
    ></v-img>

    <!-- image dialog -->
    <v-dialog v-model="imageDialog.open" max-width="600px">
      <v-card style="border-radius: 0">
        <v-card-title>
          <span class="headline">عرض الصورة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <div class="d-flex justify-center">
                <img :src="imageDialog.img" alt width="400" height="400" />
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="newPrimary" text @click="imageDialog.open = false">
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image Teacher dialog -->
  </div>
</template>

<script>
export default {
  props: {
    width: Number,
    height: Number,
    img: String,
    content_url: String
  },

  watch: {
    img () {
      this.src = this.getUrl(this.img)
    }
  },

  data () {
    return {
      src: null,

      imageDialog: {
        open: false,
        img: null
      }
    }
  },

  created () {
    this.src = this.getUrl(this.img)
  },

  methods: {
    getUrl (img) {
      const url = `${this.content_url}?img=${img}`
      return url
    },

    showImage () {
      this.imageDialog.open = true
      this.imageDialog.img = this.getUrl(this.img)
    }
  }
}
</script>
