export function getCurrentDateInString() {
    let yourDate = new Date()
    const offset = yourDate.getTimezoneOffset()
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000)
    return yourDate.toISOString().split('T')[0]
}





export function changeUtcDateAndTimeToString(dateChange) {

    const date = new Date(dateChange);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const hh = date.getHours();
    const MM = date.getMinutes();

    return `${yyyy}-${mm}-${dd} ${hh}:${MM}`
}


export function changeUtcDateToString(dateChange) {

    const date = new Date(dateChange);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`
}