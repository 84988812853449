import axios from "axios";
import sha512 from "js-sha512";

class customersApi {
  async get({ page, limit, search }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .get(`/customers?page=${page}&limit=${limit}&search=${search}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async getAll() {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .get(`/customers/getAll`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async getAllAccounts() {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .get(`/customers/getAllAccount`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async add({
    name,
    name_en,
    mobile,
    address,
    note,
    current_class,
    img,
    img_id,
    birthday,
    password_show,
    gender,
  }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .post("/customers", {
        name,
        name_en,
        mobile,
        address,
        note,
        current_class,
        img,
        img_id,
        birthday,
        password: sha512(password_show),
        password_show,
        gender,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async edit({
    id,
    name,
    name_en,
    mobile,
    address,
    note,
    current_class,
    img,
    old_img,
    img_id,
    old_img_id,
    birthday,
    password_show,
    gender,
  }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .put("/customers", {
        id,
        name,
        name_en,
        mobile,
        address,
        note,
        current_class,
        img,
        old_img,
        img_id,
        old_img_id,
        birthday,
        password: sha512(password_show),
        password_show,
        gender,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .delete(`/customers/customer_id/${id}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }
}

export default new customersApi();
